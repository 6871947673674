.label1 label {
    font-weight: 600;
    color: #666;
  }
  body {
    background: #f1f1f1;
  }
  .box8{
    box-shadow: 0px 0px 5px 1px #999;
    /* position: relative;
    top: 40px; */
  }
  .mx-t3{
    margin-top: .1rem;
  }

  .label1{
    /* background-color: red; */
    text-align: center;
  }

/* Center Loader vertically and horizontally */
.loader-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Adjust as needed */
}