  .menu {
    --menu-height: 40px;
    box-sizing: border-box;
    position: fixed;
    /* top: 0;
    left: 0; */
    width: 100vw;
     z-index: 1;
  }
  .menu ul {
    list-style: none;
    padding: 16px;
    margin: 0;
  }
  .menu ul li,
  .menu ul li a {
    /* opacity: 0.8; */
    color: #ffffff;
    cursor: pointer;
    transition: 200ms;
    text-decoration: none;
    white-space: nowrap;
    font-weight: 700;
    margin-left: 45px;
  }
  .menu ul li:hover,
  .menu ul li a:hover {
    opacity: 1;
  }
  .menu ul li a,
  .menu ul li a a {
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
  }
  .menu ul li {
    padding-right: 36px;
  }
  .menu ul li::before {
    content: "";
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #ffa500;
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
  }
  .menu ul .link::before {
    padding-right: 0;
    display: none;
  }
  .menu > ul {
    display: flex;
    height: var(--menu-height);
    align-items: center;
    background-color: #0985ea;
  }
  .menu > ul li {
    position: relative;
    margin: 0 8px;
  }
  .menu > ul li ul {
    visibility: hidden;
    opacity: 0;
    padding: 0;
    min-width: 160px;
    background-color: #333;
    position: absolute;
    top: calc(var(--menu-height) + 5px);
    left: 50%;
    transform: translateX(-50%);
    transition: 200ms;
    transition-delay: 200ms;
  }
  .menu > ul li ul li {
    margin: 0;
    padding: 8px 16px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 30px;
    padding-right: 40px;
  }
  .menu > ul li ul li::before {
    width: 0;
    height: 0;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 5px solid #ffa500;
  }
  .menu > ul li ul li ul {
    top: -2%;
    left: 100%;
    transform: translate(0);
  }
  .menu > ul li ul li:hover {
    background-color: #000000;
  }
  .menu > ul li:hover > ul {
    opacity: 1;
    visibility: visible;
    transition-delay: 0ms;
  }
