body {
    background: #f1f1f1;
  }
.box9{
    box-shadow: 0px 0px 5px 1px #999;
    position: relative;
    top: 42px;
}

.gf1{
    /* flex: 2; */
    /* margin-top: 50px; */
    text-align: center;
    position: relative;
    top: 1rem;
}
.cards{
    display: flex;
    /* gap: 9rem; */
    justify-content: center;
    
}  
.box1{
    margin: 0 auto;
}
.table1 {
    display: flex; /* Ensure h6 and label are on the same line */
    /* gap: 40 px; */
    gap: 35px;
}
.tablediv{

}
/* .table1 h6 {
    margin-right: 1rem;
    display: inline-block; 
} */

.email1{
    display: flex;
    justify-content: center;
}


/* Center Loader vertically and horizontally */
.loader-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Adjust as needed */
  }



  .transparent-button {
    background-color: transparent;
    color: gray;
    cursor: not-allowed;
    border: 1px solid gray; /* Optional: Add a border to indicate the button is disabled */
    pointer-events: none; /* Disable all pointer events */
  
  }
  .btn:disabled {
    background-color: transparent; /* Ensure this matches the transparent style */
    color: gray; 
    cursor: not-allowed;
  }