.postjob{
    /* flex: 2; */
    /* margin-top: 50px; */
    /* text-align: left; */
    position: relative;
    top: 5rem;
    /* left: 10%; */
}

label {
    font-weight: 600;
    color: #666;
  }

.postjob1{
    position: relative;
    top: 5rem;
}


