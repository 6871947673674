.gf{
    /* flex: 2; */
    /* margin-top: 50px; */
    /* text-align: left; */
    position: relative;
    top: 6rem;
}
.jobop{
    position: relative;
    top: 10px;
}
.joboplink a{
 text-decoration: none;
}

/* .joboplink a:hover{
    color: red;
} */